import styled from '@emotion/styled';
import { DOM_STATE, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';
import {
  ACCENT_COLOR,
  CSS_ALIGN,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeAccentColor,
  getThemeBackgroundColors,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeInputWidthShape,
  getThemeMainColor,
  getThemeNeutralColors,
  MAIN_COLOR,
} from './../../theme';
import { Text } from 'components/v2/Text';

export interface HomepageStyling {
  isHomepage: boolean;
}

export const AllArticlesBackgroundWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    overflow: 'hidden',
    height: 'auto',
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const AllArticlesContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  margin: `${getThemeGutter(props, TSHIRT_SIZE.XXS)} auto`,
  'div:last-of-type': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
}));

export const ArticleContainer = styled.div<ITheme & HomepageStyling>(
  ({ isHomepage, ...props }) => ({
    display: CSS_DISPLAY.GRID,
    alignItems: CSS_ALIGN.START,
    marginBottom: !isHomepage
      ? getThemeGutter(props, TSHIRT_SIZE.XXXL)
      : getThemeGutter(props, TSHIRT_SIZE.XXS),
    gridAutoColumns: '1fr',
    gap: !isHomepage
      ? getThemeGutter(props, TSHIRT_SIZE.L)
      : `16px ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
    gridTemplateColumns: '30% 1fr',
    gridTemplateRows: 'auto',
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: !isHomepage
        ? '80px'
        : getThemeGutter(props, TSHIRT_SIZE.XXS),
      columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
      gridTemplateColumns: isHomepage ? '20% 1fr' : '1fr',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: isHomepage ? '1fr' : '1fr',
    },
  })
);

export const ArticleImage = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  height: '100%',
  div: {
    width: '100%',
    img: {
      aspectRatio: '1/1',
      borderRadius: '12px',
      overflow: 'hidden',
      objectFit: 'cover !important',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    justifySelf: CSS_JUSTIFY_CONTENT.CENTER,
    div: {
      img: {
        width: '420px',
        height: '420px',
      },
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    div: {
      img: {
        width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
        height: 'auto',
      },
    },
  },
}));

export const ArticleInfoContainer = styled.div<HomepageStyling>(
  (isHomepage) => ({
    display: CSS_DISPLAY.FLEX,
    height: '100%',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: !isHomepage
      ? CSS_JUSTIFY_CONTENT.SPACE_BETWEEN
      : CSS_JUSTIFY_CONTENT.FLEX_START,
  })
);

export const ArticleTitle = styled.h3<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  color: getThemeNeutralColors(props).deep,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: CSS_ALIGN.CENTER,
  },
  [DOM_STATE.HOVER]: {
    textDecoration: 'underline',
  },
}));

export const ArticleDescription = styled(Text)<ITheme & HomepageStyling>(
  ({ isHomepage, ...props }) => ({
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontSize: !isHomepage
      ? getThemeFontSize(props, TEXT_TYPE.H6)
      : getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: !isHomepage
      ? getThemeFontLineHeight(props, TEXT_TYPE.H6)
      : getThemeFontLineHeight(props, TEXT_TYPE.P2),
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: '15px',
    },
  })
);

const ArticleTopicsContainer = styled.div<ITheme & HomepageStyling>(
  ({ isHomepage, ...props }) => ({
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
    a: {
      marginRight: '6px',
    },
    [MEDIA_QUERIES.TABLET]: {
      marginTop: !isHomepage ? getThemeGutter(props, TSHIRT_SIZE.S) : '6px',
    },
  })
);

const ArticleDetailsContainer = styled.div<ITheme>((props) => ({
  height: 'auto',
  display: CSS_DISPLAY.GRID,
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  alignItems: CSS_ALIGN.CENTER,
  gridAutoColumns: '1fr',
  gap: '16px',
  gridTemplateColumns: '20% 1fr 1fr 1.25fr',
  [MEDIA_QUERIES.TABLET]: {
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
    columnGap: '16px',
    gridTemplateColumns: '1fr 1fr',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '2fr 1fr',
    rowGap: getThemeGutter(props, TSHIRT_SIZE.S),
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    minWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  },
}));

const ReadmoreContainer = styled.div<ITheme>((props) => ({
  gridRowStart: 1,
  gridRowEnd: 2,
  gridColumnStart: 1,
  gridColumnEnd: 2,
  width: 'auto',
  height: 'auto',
  minWidth: 'auto',
  paddingTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.S),
  paddingBottom: getThemeInputPaddingShape(props, TSHIRT_SIZE.M),
  clear: 'none',
  border: `1px solid ${getThemeAccentColor(props, ACCENT_COLOR.COLOR1)}`,
  borderRadius: '6px',
  backgroundColor: getThemeBackgroundColors(props).secondary,
  fontFamily: getThemeFontFamily(props),
  color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
  fontWeight: 500,
  textAlign: CSS_ALIGN.CENTER,
  [DOM_STATE.HOVER]: {
    a: {
      padding: `9px 14px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
      borderWidth: '2px',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRowStart: 2,
    gridRowEnd: 3,
    gridColumnEnd: 3,
    padding: `${getThemeInputPaddingShape(
      props,
      TSHIRT_SIZE.S
    )} 15px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)}`,
    marginRight: '21px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    minWidth: 'auto',
    gridRowStart: 3,
    gridRowEnd: 4,
  },
}));

const TopicsTitleContainer = styled.strong((props) => ({
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  fontWeight: '600',
}));

const ArticleTimeContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  alignItems: CSS_ALIGN.CENTER,
  gridTemplateColumns: 'auto 1fr',
  float: 'left',
  gridRowStart: 1,
  gridRowEnd: 2,
  gridColumnStart: 2,
  gridColumnEnd: 3,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  color: getThemeBackgroundColors(props).deep,
  svg: {
    marginLeft: '20px',
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
    height: '25px',
    width: '25px',
  },
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_END,
    width: 'auto',
    gridRowStart: 2,
    gridRowEnd: 3,
    gridColumnStart: 3,
    gridColumnEnd: 5,
    justifyItems: CSS_JUSTIFY_CONTENT.FLEX_END,
    svg: {
      marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    justifySelf: CSS_JUSTIFY_CONTENT.FLEX_START,
    svg: {
      marginLeft: getThemeGutter(props, TSHIRT_SIZE.XXS),
      marginRight: '10px',
    },
  },
}));

export const HideableTimeContainer = hideable(ArticleTimeContainer);

export const ArticleRatingContainer = styled.div(({}) => ({
  gridRowStart: 1,
  gridRowEnd: 2,
  gridColumnStart: 3,
  gridColumnEnd: 4,
  [MEDIA_QUERIES.TABLET]: {
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridColumnStart: 3,
    gridColumnEnd: 5,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridRowStart: 2,
    gridRowEnd: 3,
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
}));

export const ArticleAuthorsContainer = styled.div<ITheme>((props) => ({
  height: '100%',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  alignItems: CSS_ALIGN.CENTER,
  gridColumnEnd: 5,
  gridColumnStart: 4,
  gridRowEnd: 2,
  gridRowStart: 1,
  [MEDIA_QUERIES.TABLET]: {
    gridColumnEnd: 4,
    gridColumnStart: 1,
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridColumnEnd: 3,
  },
}));

export const ArticleAuthorsImageWrapper = styled.div<ITheme>((props) => ({
  flexShrink: 0,
  width: '42px',
  height: '42px',
  marginRight: '6px',
  float: 'left',
  div: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    height: '100%',
  },
  img: {
    objectFit: 'cover !important',
    borderRadius: '50%',
    display: CSS_DISPLAY.INLINE_BLOCK,
  },
}));

export const ArticleAuthorsInfoContainer = styled.div(({}) => ({
  height: '100%',
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
}));

export const ArticleauthorName = styled.div<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontWeight: '600',
  color: getThemeBackgroundColors(props).deep,
}));

export const ArticleReleaseDate = styled.div<ITheme>((props) => ({
  float: 'left',
  fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
  fontWeight: 400,
}));

export const StyledTopicsLink = styled.span<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
  [DOM_STATE.HOVER]: {
    textDecoration: 'underline',
  },
}));

export const HideableTopics = hideable(ArticleTopicsContainer);
export const HideableReadmore = hideable(ReadmoreContainer);
export const HideableTopicsTitle = hideable(TopicsTitleContainer);
export const HideableArticleDetailsContainer = hideable(
  ArticleDetailsContainer
);
